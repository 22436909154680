// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from 'vue';
import App from './App';
import vuetify from './plugins/vuetify';
import './style/icons.css';

// 设置 Vue 3 特性标志
window.__VUE_PROD_HYDRATION_MISMATCH_DETAILS__ = false;
window.__VUE_OPTIONS_API__ = true;
window.__VUE_PROD_DEVTOOLS__ = false;

const app = createApp(App);
app.use(vuetify);
app.mount('#app');
