import 'vuetify/styles';
import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

const vuetifyOptions = {
  theme: {
    themes: {
      light: {
        primary: '#448aff'
      }
    }
  }
};

export default createVuetify({
  components,
  directives,
  ...vuetifyOptions
});
