<template>
  <v-app>
    <v-app-bar color="primary">
      <v-app-bar-title class="white--text">证件水印</v-app-bar-title>
    </v-app-bar>
    <v-main>
      <v-form>
        <v-container :style="{'max-width': CONTENT_MAX_WIDTH + 'px'}">
          <v-row justify="center" class="flex-wrap">
            <v-col cols="12" class="px-2 mb-4">
              <v-text-field
                v-model="filename"
                label="选择图片"
                single-line
                :readonly="true"
                data-lpignore="true"
                @click="onFocus"
                prepend-icon="mdi-file-image"
              ></v-text-field>
              <input
                type="file"
                accept="image/*"
                :multiple="false"
                data-lpignore="true"
                ref="fileInput"
                @change="uploadHandler"
              >
            </v-col>
            <v-col cols="12" class="px-2 mb-4">
              <v-text-field v-model="text" data-lpignore="true" label="输入水印文字"></v-text-field>
            </v-col>
            <v-col cols="6" class="px-2 mb-4">
              <v-select
                v-model="color"
                :items="colorOptions"
                item-title="title"
                item-value="value"
                label="文字颜色"
                @update:model-value="watermarkHandler"
              >
                <template v-slot:selection="{ item }">
                  <v-avatar
                    size="20"
                    :class="{ 'avatar-border': item.value === '#fff'}"
                    style="margin-right: 16px;"
                    :color="item.value"
                  ></v-avatar>
                  {{ item.title }}
                </template>
                <template v-slot:item="{ item, props }">
                  <v-list-item v-bind="props">
                    <template v-slot:prepend>
                      <v-avatar
                        size="20"
                        :class="{ 'avatar-border': item.value === '#fff'}"
                        style="margin-right: 16px;"
                        :color="item.value"
                      ></v-avatar>
                    </template>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
            <v-col cols="6" class="px-2 mb-4">
              <v-select
                v-model="opacity"
                :items="opacityOptions"
                item-title="title"
                item-value="value"
                label="透明度"
                @update:model-value="watermarkHandler"
              ></v-select>
            </v-col>
            <v-col cols="6" class="px-2 mb-4">
              <v-select
                v-model="fontsize"
                :items="fontSizeOptions"
                item-title="title"
                item-value="value"
                label="文字大小"
                @update:model-value="watermarkHandler"
              ></v-select>
            </v-col>
            <v-col cols="6" class="px-2 mb-4">
              <v-select
                v-model="angle"
                :items="angleOptions"
                item-title="title"
                item-value="value"
                label="文字角度"
                @update:model-value="watermarkHandler"
              ></v-select>
            </v-col>
            <v-col cols="12" class="px-2 mb-4">
              <v-btn
                @click="downloadHandler"
                color="primary"
                depressed
                :ripple="false"
                class="md-button"
              >下载图片</v-btn>
            </v-col>
            <v-col cols="12" class="px-2 mb-4">
              <div
                id="image-holder"
                :style="{ 'height': image ? image.height * scale + 'px' : 'auto'}"
              >
                <canvas
                  ref="draw"
                  :style="{'transform': 'scale(' + scale + ')'}"
                  :width="image ? image.width : ''"
                  :height="image ? image.height : ''"
                ></canvas>
              </div>
            </v-col>
            <v-snackbar
              :color="snackbarColor"
              v-model="snackbar"
              :timeout="4000"
              location="top"
            >{{ message }}</v-snackbar>
          </v-row>
        </v-container>
      </v-form>
    </v-main>
    <v-footer class="pa-3">
      <v-row justify="center">
        <v-col cols="12">
          <div>&copy; {{ new Date().getFullYear() }}&nbsp;证件水印：idshuiyin.com</div>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import hexToRgba from "hex-to-rgba";
import { dataURItoBlob } from "./utils";

const FILE_SIZE_LIMIT = 8;
// 最大的内容宽度，表现为在宽屏显示器下表单和图片的宽度
const CONTENT_MAX_WIDTH = 800;

export default {
  name: "App",

  data() {
    return {
      CONTENT_MAX_WIDTH,
      snackbar: false,
      snackbarColor: "error",
      message: "",
      text: "仅供办理XXX使用，它用无效",
      image: null,
      imageCan: null,
      fontsize: "36",
      fontSizeOptions: [
        { title: "极小: 16px", value: "16" },
        { title: "稍小: 24px", value: "24" },
        { title: "一般: 36px", value: "36" },
        { title: "中等: 48px", value: "48" },
        { title: "较大: 60px", value: "60" },
        { title: "极大: 72px", value: "72" },
        { title: "最大: 100px", value: "100" }
      ],
      angle: "45",
      angleOptions: [
        { title: "水平", value: "0" },
        { title: "15°", value: "15" },
        { title: "30°", value: "30" },
        { title: "45°", value: "45" },
        { title: "60°", value: "60" },
        { title: "75°", value: "75" }
      ],
      color: "#fff",
      colorOptions: [
        { title: "白色", value: "#fff" },
        { title: "黑色", value: "#000" },
        { title: "灰色", value: "#757575" },
        { title: "紫色", value: "#6200EE" },
        { title: "红色", value: "#D32F2F" },
        { title: "粉色", value: "#D81B60" },
        { title: "蓝色", value: "#2979FF" },
        { title: "青色", value: "#00ACC1" },
        { title: "黄色", value: "#FFEA00" },
        { title: "橙色", value: "#FF9100" },
        { title: "棕色", value: "#6D4C41" }
      ],
      opacity: "0.45",
      opacityOptions: [
        { title: "极大", value: "0.15" },
        { title: "大", value: "0.3" },
        { title: "中等", value: "0.45" },
        { title: "较小", value: "0.6" },
        { title: "小", value: "0.8" },
        { title: "不透明", value: "1" }
      ],
      filename: "",
      watermarkHeight: 0,
      watermarkWidth: 0,
      textWidth: 0,
      textHeight: 0,
      centerX: 0,
      centerY: 0,
      scale: 1
    };
  },

  computed: {
    afterText() {
      return ` ${this.text} `;
    }
  },

  mounted() {
    this.imageCan = this.$refs.draw;
  },

  methods: {
    downloadHandler() {
      let that = this;
      if (!that.image) {
        that.showMessage("未选择图片", "warning");
        return;
      }
      let dataURI = this.$refs.draw.toDataURL("image/jpeg", 0.9);
      let blob = dataURItoBlob(dataURI);
      let downloadA = document.createElement("a");
      document.body.appendChild(downloadA);
      let url = URL.createObjectURL(blob);
      downloadA.href = url;
      downloadA.download =
        that.filename.replace(/\.[^/.]+$/, "") + "_idshuiyin.jpeg";
      setTimeout(() => {
        downloadA.click();
      }, 100);
    },

    configureHandler() {
      let textCanvas = document.createElement("canvas");
      let ctx = textCanvas.getContext("2d"); //text ctx
      ctx.font = `${this.fontsize}px Courier`;

      this.textWidth = ctx.measureText(this.afterText).width;
      this.textHeight = ctx.measureText("--").width;

      this.watermarkWidth =
        this.textWidth * Math.cos((Math.PI * Math.abs(this.angle)) / 360) +
        this.textHeight * Math.sin((Math.PI * Math.abs(this.angle)) / 360);
      this.watermarkHeight = this.watermarkWidth;

      this.centerX = this.watermarkWidth / 2;
      this.centerY = this.watermarkHeight / 2;
    },

    computeScale(imageWidth) {
      let contentWidth = document.querySelector("#image-holder").offsetWidth;
      if (contentWidth >= imageWidth) {
        return 1;
      } else {
        return contentWidth / imageWidth;
      }
    },

    showMessage(message, color = "error") {
      this.message = message;
      this.snackbarColor = color;
      this.snackbar = true;
    },

    watermarkHandler() {
      if (!this.image) return;
      this.configureHandler();
      let targetCtx = this.$refs.draw.getContext("2d"); // image ctx
      targetCtx.drawImage(
        this.image,
        0,
        0,
        this.image.width,
        this.image.height
      );

      let watermarkCanvas = document.createElement("canvas");
      watermarkCanvas.height = this.watermarkHeight;
      watermarkCanvas.width = this.watermarkWidth;

      let wmctx = watermarkCanvas.getContext("2d"); // watermark ctx

      wmctx.font = `${this.fontsize}px Courier`;
      wmctx.fillStyle = hexToRgba(this.color, this.opacity);
      wmctx.textAlign = "center";
      wmctx.textBaseline = "middle";

      wmctx.translate(this.centerX, this.centerY);
      wmctx.rotate((-this.angle * Math.PI) / 180);
      wmctx.fillText(this.afterText, 0, 0);
      wmctx.translate(-this.centerX, -this.centerY);

      targetCtx.fillStyle = targetCtx.createPattern(watermarkCanvas, "repeat");
      targetCtx.fillRect(0, 0, this.image.width, this.image.height);
      this.scale = this.computeScale(this.image.width);
    },

    uploadHandler(event) {
      let that = this;
      let reader = new FileReader();
      let files = event.target.files;
      if (!files.length) return false;

      let filesize = files[0].size / 1024 / 1024;
      if (filesize > FILE_SIZE_LIMIT) {
        this.showMessage(`图片过大，请勿超过 ${FILE_SIZE_LIMIT} MB`);
        return;
      }
      this.filename = files[0].name;
      let image = new Image();
      image.onload = function() {
        that.imageWidth = this.width;
        that.imageHeight = this.height;
        that.watermarkHandler();
      };
      reader.onload = e => {
        image.src = e.target.result;
        that.image = image;
      };
      reader.readAsDataURL(files[0]);
    },

    onFocus() {
      this.$refs.fileInput.click();
    }
  }
};
</script>

<style lang="scss">
body {
  background-color: #fff;
}
#app {
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, Helvetica Neue, PingFang SC,
    Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei,
    sans-serif;
  .v-text-field__details {
    display: none;
  }
  .white--text {
    font-weight: 700;
  }
  input[type="file"] {
    position: absolute;
    left: -9999px;
  }
  .v-toolbar__content {
    max-width: 980px;
    margin: 0 auto;
  }
  a:not(.md-button):hover {
    text-decoration: none;
  }
  .avatar-border {
    border: 1px solid #fafafa;
    border-color: #e9e9ea !important;
  }
  .v-btn {
    border-radius: 2px;
  }
  .md-button {
    display: block;
    width: 100%;
    margin: 6px 0;
  }
  .v-footer {
    text-align: center;
  }
}
#image-holder {
  padding: 8px 0;
  text-align: center;
  max-width: 100%;
  overflow: hidden;
  margin-bottom: 16px;
  canvas {
    transform-origin: 0% 0%;
  }
}
</style>
